@import "./main.scss";

.main-container {
  flex-direction: column;
}

.view-size {
  height: 100vh;
}

.pagination-candles {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  height: 90px;
}

.options-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.option-buttons {
  height: 60px;
  min-width: 100px;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.buy {
  background-color: $buy-color;
}

.sell {
  background-color: $sell-color;
}

.next-button {
  color: white;
  height: 60px;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  background: $buy-color;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.bar {
  border: 1px solid white;
  width: 10px;
  height: 30px;
}

.flex-row {
  position: absolute;
  top: 0;
  gap: 10px;
}
