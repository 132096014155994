@import "./main.scss";

.main-container {
  flex-direction: column;
}

.row-container {
  display: flex;
  flex-direction: row;
}

.title {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.grid {
  display: grid;
}

.two-fr {
  grid-template-columns: 1fr 1fr;
}

.level-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 120px;
  max-width: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  text-align: center;
  color: inherit;
  text-decoration: none;
  border: 0;
  border-radius: 15px;
  &:hover {
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  &:focus {
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

.level-container .textholder {
  padding: 10px;
}

.level-container .title {
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: left;
}

.level-container .description {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: left;
}

.imageholder img {
  height: 100px;
}

.avatar {
  height: auto;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 50%; /* relative value */
  padding: 5px;
}

.home-container {
  max-width: 425px;
  width: 100%;
  margin-bottom: 10px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
