.thumbnail {
  width: 190px;
  height: 254px;
  background-color: #1a1a1a;
  border-radius: 18px;
  transition: all 0.2s;
}
.detail-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

img {
  width: 100%;
}
