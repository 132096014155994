$primary-color: #040404;
$buy-color: #0d9b84;
$sell-color: #ee3747;
$main-font-color: #ffffff;

.main-container {
  position: relative;
  padding: 3%;
  //background-image: url("../assets/bvbc.png");
  background-color: $primary-color;
  backdrop-filter: blur(10px);
  background-size: cover;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
}

.container {
  display: grid;
  gap: 20px;
  max-width: 500px;
  margin-top: -15px;
  padding: 10px;
  background-color: rgba(107, 107, 107, 0.1);
}

.container .exit {
  text-align: right;
}

.borded-lines {
  border: 1px solid $buy-color;
  border-radius: 5px;
}

.loader {
  width: 60px;
  height: 60px;
  border: 10px solid $sell-color;
  border-top-color: $buy-color;
  animation: spin013151 1s linear infinite;
  border-radius: 100%;
}

@keyframes spin013151 {
  to {
    transform: rotate(360deg);
  }
}
.section-title {
  background-color: $buy-color;
  color: #000000;
  width: fit-content;
  font-weight: bolder;
  padding: 1%;
  z-index: 1;
  position: relative;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-top {
  justify-content: flex-start;
}

.red {
  background-color: $sell-color;
}
.green {
  background-color: $buy-color;
}
