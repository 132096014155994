.each-item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 3%;
}

.card {
  width: 190px;
  height: 254px;
  background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
  border-radius: 20px;
  transition: all 0.3s;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.card2 {
  width: 190px;
  height: 254px;
  background-color: #1a1a1a;
  border-radius: 18px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card2 img {
  width: 90%;
  align-items: center;
}

.card2:hover {
  transform: scale(0.98);
  border-radius: 20px;
}

.card:hover {
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.3);
}

@media (max-width: 768px) {
  .card {
    width: 140px;
    height: 189px;
    border-radius: 20px;
  }

  .card2 {
    width: 140px;
    height: 189px;
    border-radius: 20px;
  }
}
