@import "./main.scss";

h2 {
  margin-bottom: 20px;
}
.progress-line {
  display: flex;
  flex-direction: row;
}

.result-text {
  text-align: center;
  margin-bottom: 20px;
}

.result-text p {
  font-size: 1.2em;
}

.result-image {
  margin-top: 20px;
}

.result-image img {
  max-width: 100%;
}

.buttons-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.buttons-container button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: $primary-color;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
