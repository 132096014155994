@import "./main.scss";

.progress {
  justify-content: space-between;
}

.progressbar {
  font-weight: bolder;
  text-align: end;
  padding: 5px 0px;
}
.progressbar-text {
  padding-right: 10px;
}

.red {
  background-color: $sell-color;
  width: 100%;
  z-index: -1;
}
.ontop {
  position: absolute;
}
.green {
  background-color: $buy-color;
  z-index: 1;
}

.achievements {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.achievement {
  height: auto;
  width: 100%;
}
.grayscale {
  filter: grayscale(80%);
}
@media (max-width: 768px) {
  .achievement {
    width: 33%;
  }
}
